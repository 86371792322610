exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js-content-file-path-blog-2020-08-28-no-kristiansand-index-mdx": () => import("./../../../src/templates/blog_post.js?__contentFilePath=/opt/build/repo/blog/2020-08-28-no-kristiansand/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-2020-08-28-no-kristiansand-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-2020-08-31-no-stavanger-index-mdx": () => import("./../../../src/templates/blog_post.js?__contentFilePath=/opt/build/repo/blog/2020-08-31-no-stavanger/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-2020-08-31-no-stavanger-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-2020-09-04-no-bergen-index-mdx": () => import("./../../../src/templates/blog_post.js?__contentFilePath=/opt/build/repo/blog/2020-09-04-no-bergen/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-2020-09-04-no-bergen-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-2021-10-24-finnland-first-impressions-index-mdx": () => import("./../../../src/templates/blog_post.js?__contentFilePath=/opt/build/repo/blog/2021-10-24-finnland-first-impressions/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-2021-10-24-finnland-first-impressions-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-2021-10-28-fin-road-trip-index-mdx": () => import("./../../../src/templates/blog_post.js?__contentFilePath=/opt/build/repo/blog/2021-10-28-fin-road-trip/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-2021-10-28-fin-road-trip-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-2022-01-07-fin-student-index-mdx": () => import("./../../../src/templates/blog_post.js?__contentFilePath=/opt/build/repo/blog/2022-01-07-fin-student/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-2022-01-07-fin-student-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-2024-03-lima-index-mdx": () => import("./../../../src/templates/blog_post.js?__contentFilePath=/opt/build/repo/blog/2024-03-lima/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-2024-03-lima-index-mdx" */)
}

